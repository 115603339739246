import React from "react";
import TextLoop from "react-text-loop";

export default () => (
  <div className="container-small">
    <div className="pad-10 pad-20-t pad-20-b">
      <div className="row">
        <div className="col-xs-12">
          <h2>
            The perfect role for me would include{" "}
            <TextLoop>
              <span className="special-label">Product Design</span>
              <span className="special-label">UX Design</span>
              <span className="special-label">Front-end Tech</span>
              <span className="special-label">Rapid Prototyping</span>
            </TextLoop>
            {"."}
          </h2>
        </div>
      </div>
    </div>
  </div>
);
