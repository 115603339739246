import React from "react";
import Proficiency from "./Proficiency";
export default () => (
  <>
    <div id="sam" className="pad-10-l pad-10-r pad-10-t  ">
      <div className="row container-small">
        <div className="col-xs-12 col-md-6">
          <h1 className="is-hero-menu">Sam Larsen-Disney</h1>
          <h1 className="">
            Design Engineer working in R&D @
            <span
              style={{ color: "#046ED0", cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.americanexpress.com", "_blank");
              }}
            >
              {" "}
              American Express.
            </span>
          </h1>
          <p>
            I joined American Express on their technolgy summer internship. I
            completed two six month rotations on their graduate scheme. I tried
            my hand as a Front End developer and User Experience Designer and
            realised that I wanted my role to be a mixture of the two.
          </p>
        </div>

        <div className="col-xs-12 col-md-6 phone-1" />
      </div>
    </div>

    <div className="pad-10-l pad-10-r pad-10-t  is-light-grey-bg">
      <div className="row container-small">
        <div className="col-xs-12 col-md-6 phone-2" />
        <div className="col-xs-12 col-md-6">
          <p>
            Since then I have joined 'Refer A Friend' in a Research &
            Develpoment team as a Design Engineer. Here I build new products and
            design the future of the refer a friend scheme at American Express.
            The team is constantly ideating using tools such as the design
            sprint process and lightening decision jams, both of which I have
            facilitated as well as participated in.
          </p>
          <p>
            The projects I have worked on have been incredibly varied. I have
            built web-ready animations, onboarding experiences, faster sites in
            newer frameworks, chat bots and so much more. Sadly, due to the
            nature of my work, I cannot share much information on these
            projects.
          </p>
        </div>
      </div>
    </div>

    <div className="pad-10-l pad-10-r pad-20-t pad-20-b ">
      <div className="row container-small">
        <div className="row">
          <div className="col-xs-12">
            <Proficiency />
            <div className="line is-monzo-deep-blue" />
          </div>
          <div className="col-xs-12">
            <h1 className="is-hero-menu">Aside from the day job.</h1>
          </div>

          <div className="col-xs-12 ">
            <p>
              I have always enjoyed leading people. I was the only graduate in
              my cohort to formally manage interns and have gone on to be the
              youngest engineer to manage graduates.
            </p>
          </div>
          <div className="col-xs-12 ">
            <p>
              When I'm not in the office I like to attend hackathons. At these
              events I enjoy teaching the next generation to code and have a bit
              of a reputation for staying up all night. I also like to take{" "}
              <a href="https://sld.codes/photos">photos</a>,{" "}
              <a href="https://sld.codes/articles">write about tech</a> and{" "}
              <a href="https://sld.codes/projects">
                tinker with weekend coding projects
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);
