import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default () => (
  <div className="hero">
    <div className="row container-small pad-10-l pad-10-r">
      <div className="col-xs-12 col-md-6 pad-30-t pad-30-b">
        <h1 className="is-hero-menu">Hi Monzo,</h1>
        <p className="pad-5-b">
          My name's Sam and I have a question for you. You've helped 3.7 million
          people change the way they bank but can you help me find my next job
          opportunity?
        </p>
        <div className="row">
          <div className="col-xs-12 col-sm-4 flex align-vertical pad-3-b">
            <AnchorLink className="btn" href="#sam">
              Who's Sam
            </AnchorLink>
          </div>
          <div className="col-xs-12 col-sm-4 flex align-vertical pad-3-b">
            <AnchorLink className="btn" href="#monzo">
              Why I{" "}
              <span role="img" aria-label="love">
                ❤️
              </span>{" "}
              Monzo{" "}
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  </div>
);
