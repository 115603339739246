import React from "react";

export default () => (
  <div id="contact" className="pad-10 pad-20-t pad-20-b container-small">
    <div className="row">
      <div className="col-xs-12">
        <h1 className="is-hero-sub-text">Contact Me</h1>
        <h3>
          <span role="img" aria-label="Idea">
            💡
          </span>{" "}
          If the perfect opportunity comes along please do get in touch.
        </h3>
        <h3 className="is-monzo-pink">
          <a
            href="mailto:s.larsendisney@gmail.com?Subject=Hi Sam, Monzo here!"
            className="is-monzo-pink"
            style={{ textDecoration: "none" }}
          >
            s.larsendisney@gmail.com
          </a>
        </h3>
        <h3 className="is-monzo-pink">+447926147958</h3>
      </div>
      <div className="col-xs-12">
        <h3>
          You can see more of what I do at{" "}
          <a href="https://sld.codes" className="link">
            sld.codes
          </a>
          . You can download my CV{" "}
          <a
            href="https://sld.codes/static/CV-c2a8b74388a43529ceab5465bc0a7e1f.pdf"
            className="link"
          >
            here
          </a>
          .
        </h3>
        <h3>Thanks for your time.</h3>
      </div>
    </div>
  </div>
);
