import React from "react";

export default () => (
  <div id="monzo" className="pad-10 pad-20-t pad-20-b container-small">
    <div className="row">
      <div className="col-xs-12">
        <h1 className="is-hero-menu">
          Why I{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>{" "}
          Monzo
        </h1>
      </div>
      <div className="col-xs-12">
        <h1 style={{ fontFamily: "monzo-bold" }}>
          <span role="img" aria-label="muscle">
            💪
          </span>{" "}
          You're Small and Mighty
        </h1>
        <p>
          I have come to realise that company size isn't everything. I am
          attracted to the fact you are 1/40th the size of my current company.
          Because you're smaller you are able to make big changes fast - I'm
          thinking of your recent app redesign as an example.
        </p>
      </div>
      <div className="col-xs-12">
        <h1 style={{ fontFamily: "monzo-bold" }}>
          <span role="img" aria-label="creativity">
            👩‍🎨
          </span>{" "}
          Your Design
        </h1>
        <p>
          It's clear. It's bold. It's beautiful. It allows users to make sense
          of the jargon that comes with online banking. All while using emojis
          an appropriate amount!
        </p>
      </div>
      <div className="col-xs-12">
        <h1 style={{ fontFamily: "monzo-bold" }}>
          <span role="img" aria-label="safety">
            🥽
          </span>{" "}
          Accessibility & Performance
        </h1>
        <p>
          One thing that my work as a design engineer has instilled in me, is
          the importance of performance and accessibility in modern web
          development. I must admit, when I performed a lighthouse audit on your
          webpage it was refreshing to see a sea of green pass marks. This is
          something I rarely come across at my current company.
        </p>
      </div>
      <div className="col-xs-12">
        <h1 style={{ fontFamily: "monzo-bold" }}>
          <span role="img" aria-label="support">
            🧑‍💻
          </span>{" "}
          Customer Support
        </h1>
        <p>
          I have only ever had to use your customer support once - I was
          accidentally charged twice by a merchant while abroad. The chat bot
          flow you have in your app blew my mind. I answered three questions and
          a minute later I had a real person confirming they had fixed the
          problem. It's the first time I have come out of customer support
          interaction with a smile on my face and it was the push I needed to
          finally put my salary into Monzo.
        </p>
      </div>
    </div>
  </div>
);
