import React from "react";
import Fade from "react-reveal/Fade";

const items = [
  "Javascript",
  "HTML5",
  "ReactJS",
  "Redux",
  "GatsbyJS",
  "Node",
  "CI/CD pipelines",
  "Adobe Suite",
  "Sketch",
  "UX Design"
];

class FadeExample extends React.Component {
  render() {
    return (
      <div>
        <h2 className="is-hero-sub-text">I am proficient in:</h2>
        <h2>
          <Fade cascade right>
            {items.map(item => (
              <span>{`${item}, `}</span>
            ))}
          </Fade>
          Rapid Prototyping & much more.
        </h2>
      </div>
    );
  }
}

export default FadeExample;
