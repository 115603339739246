import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default () => (
  <div className="container-small">
    <div className="row pad-5-r pad-5-l pad-5-t pad-5-b">
      <div className="col-xs-3">
        <div className="main-nav-logo"></div>
      </div>

      <div className="col-xs-9 flex" style={{ justifyContent: "flex-end" }}>
        <h4 className="special-label-top margin-auto pad-0 margin-10-r ">
          An Aspiring Monzonaught
        </h4>
        <AnchorLink className="btn-small" href="#contact">
          Contact Me
        </AnchorLink>
      </div>
    </div>
  </div>
);
