import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import Sam from "../components/Sam";
import Expertise from "../components/Expertise";
import Monzo from "../components/Monzo";
import TopBar from "../components/TopBar";

export default function Start() {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="is-white is-monzo-deep-blue-bg">
        <TopBar />
      </div>
      <div className="is-white is-monzo-deep-blue-bg">
        <Hero />
      </div>

      <div className="is-white-bg is-monzo-deep-blue">
        <Sam />
      </div>
      <div className="is-monzo-pink-bg is-white">
        <Expertise />
      </div>
      <div className="is-monzo-white-bg is-monzo-deep-blue">
        <Monzo />
      </div>
      <div className="is-light-grey-bg is-monzo-deep-blue">
        <Contact />
      </div>
    </Layout>
  );
}
